<template>
  <div>
    <KTCodePreview v-bind:title="'Chuyển cửa hàng'">
      <template v-slot:preview>
        <!-- Header session -->
        <b-row class="mb-5">
          <b-col md="3" style="align-self: flex-end">
            <b-row>
              <label
                ><strong class="mr-2">Cửa hàng hiện tại:</strong>
                {{ currentStore }}</label
              >
              <b-col class="ml-2">
                <i
                  class="fas fa-arrow-circle-right"
                  style="font-size: 18px"
                ></i>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="3">
            <vue-autosuggest
              v-model="searchStock"
              :suggestions="filteredOptions"
              @selected="onSelected"
              :limit="10"
              @input="onInputChange"
              :input-props="{
                id: 'autosuggest__input',
                placeholder: 'Nhập tên cửa hàng',
              }"
              ><div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                {{ suggestion.item.suggestionName }}
              </div>
            </vue-autosuggest>
          </b-col>
          <b-col md="1">
            <b-button variant="primary" size="sm" @click="onSubmit()"
              >Lưu</b-button
            >
          </b-col>
        </b-row>

        <!-- Paginate session -->
        <!-- End of Paginate session -->
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { VueAutosuggest } from 'vue-autosuggest';
import localData from '../../../utils/saveDataToLocal';

export default {
  data() {
    return {
      currentStore: '',
      searchStock: '',
      options: [
        {
          data: [],
        },
      ],
      filteredOptions: [],
      selectedStore: null,
      listStoreId: [],
      empId: null,
    };
  },
  methods: {
    fetchStore: async function () {
      this.options[0].data = [];
      ApiService.setHeader();
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          let store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.options[0].data.push(store);
        });
        this.filteredOptions = [{ data: this.options[0].data }];
      });
    },
    onSelected(option) {
      this.selectedStore = option.item.id;
      this.searchStock = option.item.name;
    },
    onInputChange(text) {
      if (!text) {
        text = '';
      }
      this.searchStock = text;
      // if (text === '' || text === undefined) {
      //   return;
      // }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
      //this.fetchStock();
    },
    /**
     * This is what the <input/> value is set to when you are selecting a suggestion.
     */
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    getEmployeeById: async function () {
      const employeeInfo = localData.getData('employee_info');

      if (employeeInfo) {
        if (employeeInfo) {
          const tmpInfo = JSON.parse(employeeInfo);
          this.empId = tmpInfo.id;
        }
      }
      if (this.empId) {
        ApiService.query('employees' + '/' + this.empId).then((response) => {
          const dataRes = response.data.data;
          this.id = dataRes.id;
          this.selectedStore = dataRes.storeId;
          this.currentStore = dataRes.storeName
            ? dataRes.storeName
            : 'Chưa được gán';
          const stores = dataRes.listStore;
          this.options[0].data = stores.map((element) => {
            return {
              id: element.id,
              name: element.name,
              shortName: element.shortName,
              suggestionName: element.shortName + ' ( ' + element.name + ')',
            };
          });
          this.filteredOptions = [{ data: this.options[0].data }];
        });
      }
    },
    onSubmit() {
      if (this.searchStock.trim() === '') {
        this.selectedStore = null;
      }

      if (!this.selectedStore) {
        this.makeToastFaile('Vui lòng chọn cửa hàng bạn muốn chuyển!');
      }

      const data = {
        id: this.empId,
        storeId: this.selectedStore,
      };

      ApiService.put('employees/store-employee', data).then((data) => {
        const { status, message } = data.data;
        if (status === 1) {
          this.makeToastSuccess('Chuyển cửa hàng thành công!');
          this.getEmployeeById();
        } else {
          this.makeToastFaile(message);
        }
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Thông tin cá nhân', route: 'profile' },
      { title: 'Chuyển cửa hàng' },
    ]);
    this.getEmployeeById();
  },
  created() {},
  components: {
    KTCodePreview,
    VueAutosuggest,
  },
};
</script>

<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}

input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

select {
  height: 2.5em;
}

.example {
  border: 1px solid #ddd;
  margin-bottom: 2em;
}

code,
pre {
  margin: 1em 0;
  padding: 1em;
  border: 1px solid #bbb;
  display: block;
  background: #ddd;
  border-radius: 3px;
}

.settings {
  margin: 2em 0;
  border-top: 1px solid #bbb;
  background: #eee;
}

/* h5 {
  font-size: 100%;
  padding: 0;
} */

.form-group {
  margin-bottom: 1em;
}

.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}
.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5eaee;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.productCode:hover {
  text-decoration: underline;
}
</style>
